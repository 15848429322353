<template>
  <div>
    <form @submit.prevent="toggleModal">
      <loading-button
        :is-loading="false"
        secondary
        :is-enabled="items.length > 0"
      >
        Assign to...
      </loading-button>
    </form>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="assign-to"
      size="xs"
      :modal-title="`Assign to...`"
    >
      <div class="mb-4">
        Choose who you want to assign these {{ items.length }} items to...
      </div>
      <div class="mb-6">
        <div class="flex">
          <dropdown-button is-full is-input>
            <template v-slot:button>
              <div class="flex items-center w-full space-x-2">
                <div class="text-gray-500">
                  <avatar
                    class="flex-none"
                    size="sm"
                    :key="assignedTo.name"
                    :email="assignedTo.name"
                    v-if="assignedTo.id !== 12"
                  />
                  <div v-else class="text-gray-400">
                    <font-awesome-icon :icon="['fal', 'user-slash']" />
                  </div>
                </div>
                <div
                  class="text-black"
                  :class="{ 'text-gray-400': assignedTo.id === 12 }"
                >
                  {{ assignedTo.name }}
                </div>
              </div>
            </template>
            <div class="py-2">
              <div class="pt-1 pb-1 mb-2 text-gray-400 px-4 italic">
                Assign to...
              </div>
              <div
                v-for="packer in availablePackers"
                :key="`packer-${packer.id}`"
                class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                @click="assignedTo = packer"
                :class="
                  assignedTo.packerid === packer.id
                    ? 'pointer-events-none'
                    : 'cursor-pointer'
                "
              >
                <avatar
                  class="flex-none"
                  size="sm"
                  :email="packer.name"
                  v-if="packer.id !== 12"
                />
                <div v-else class="text-gray-400">
                  <font-awesome-icon :icon="['fal', 'user-slash']" />
                </div>
                <div
                  class="flex-grow truncate"
                  :class="{ 'text-gray-400': packer.id === 12 }"
                >
                  {{ packer.name }}
                </div>
                <div v-if="assignedTo">
                  <font-awesome-icon
                    v-if="assignedTo.packerid == packer.id"
                    :icon="['fal', 'check']"
                  />
                </div>
              </div>
            </div>
          </dropdown-button>
        </div>
      </div>
      <form @submit.prevent="assignPackers">
        <loading-button :is-loading="isLoading" :is-enabled="true">
          Assign packer</loading-button
        >
      </form>
    </modal>
  </div>
</template>

<script>
import DropdownButton from '@/components/common/ui/DropdownButton';
import LoadingButton from '@/components/common/ui/LoadingButton';
import { cloneDeep, filter, find } from 'lodash/fp';
import utils from '@/utils';
import Avatar from '@/components/common/ui/Avatar';
import Modal from '@/components/common/ui/Modal';
import { mapGetters } from 'vuex';
export default {
  components: {
    LoadingButton,
    Modal,
    Avatar,
    DropdownButton
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    packers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      modalVisible: false,
      availablePackers: [],
      selectAll: false,
      assignedTo: null,
      isLoading: false
    };
  },

  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },
    getKey() {
      return utils.createKey('items', 6);
    },
    getCount(id) {
      return find(['id', id], this.selectedPackersCount).count;
    },
    deselectAll() {
      this.availablePackers.forEach(p => {
        p.isSelected = false;
      });
    },
    assignPackers() {
      this.isLoading = true;
      const promises = [];
      this.items.forEach(i => {
        promises.push(
          this.$emit('reassign-packer', {
            packer: this.assignedTo,
            pack: i
          })
        );
      });
      Promise.allSettled(promises)
        .then(() => {
          this.isLoading = false;
          this.toggleModal();
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },
  watch: {
    selectAll(newValue) {
      this.availablePackers.forEach(p => {
        p.isSelected = newValue;
      });
    }
  },
  computed: {
    ...mapGetters({
      activePackers: 'activePackersGetter'
    }),
    itemsChunk() {
      const items = cloneDeep(this.items);
      const chunkArr = (arr, size) =>
        arr.reduceRight((r, i, _, s) => (r.push(s.splice(0, size)), r), []);

      return chunkArr(
        items,
        Math.ceil(items.length / this.selectedPackers.length)
      );
    },
    selectedPackers() {
      return filter(['isSelected', true], this.availablePackers);
    },
    selectedPackersCount() {
      const packers = this.selectedPackers;
      packers.forEach((p, i) => {
        this.$set(p, 'count', this.itemsChunk[i].length);
      });
      return packers;
    }
  },

  mounted() {
    const packers = cloneDeep(this.activePackers);
    packers.forEach(p => {
      this.$set(p, 'isSelected', false);
    });
    this.availablePackers = packers;
    this.assignedTo = this.availablePackers[0];
  }
};
</script>

<style lang="scss"></style>
