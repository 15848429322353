<template>
  <div class="p-4 pt-0">
    <div
      v-if="notes.length > 0"
      class="bg-yellow-100 rounded-lg text-gray-800 text-base font-normal  p-4 flex   space-x-4  items-stretch    "
    >
      <div class="relative w-20 flex-none flex-col flex relative ">
        <div
          class="w-16 h-12 rounded bg-yellow-200 absolute -top-6 left-1.5"
        ></div>
        <font-awesome-icon
          :icon="['fal', 'message-lines']"
          fixed-width
          class="text-6xl leading-none transform text-gray-700 rotate-12 absolute -top-6   "
        />
      </div>

      <div class="flex-grow divide-y divide-yellow-200">
        <div
          v-for="(note, index) in notes"
          class="py-3 flex flex-col relative group"
          :key="`note-${index}`"
        >
          <div class="text-md">
            {{ note.note }}
          </div>

          <div
            class="flex mt-3  text-sm  italic space-x-2 opacity-70 items-center "
          >
            <div>
              Added by
              {{ note.user.name }}
              &middot;
              {{ note.created | timeago }}
            </div>

            <div v-if="note.isFuture" class="italic">
              <font-awesome-icon :icon="['fal', 'crystal-ball']" />
              Automatically added
            </div>
            <div v-if="isAdmin && !note.isFuture">&middot;</div>
            <div
              v-if="isAdmin && !note.isFuture"
              class="cursor-pointer hover:underline "
              @click="removeNote(note, index)"
            >
              <font-awesome-icon :icon="['fal', 'trash-can']" /> Remove
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utils from '@/utils';
export default {
  name: 'PackingNotes',
  props: {
    uid: {
      type: String
    }
  },
  data() {
    return {
      notes: []
    };
  },
  computed: {
    ...mapGetters({
      packingItem: 'currentPackingItemGetter',
      isAdmin: 'isAdmin'
    })
  },
  methods: {
    removeNote(note, index) {
      const endpoint = `api/packers/deliveries/${this.$route.params.id}/dispatchnotes`;
      this.$store.dispatch('removeNote', { endpoint }).then(() => {
        this.notes.splice(index, 1);
      });
    }
  },
  mounted() {
    const notes = [];
    if (this.packingItem.currentDetails?.futureNotes) {
      this.packingItem.currentDetails.futureNotes.forEach(fn => {
        notes.push({
          note: fn.note,
          created: fn?.date ? utils.convertTimestamp(fn.date) : 'Unknown',
          user: { id: fn.packer.id, name: fn.packer.name },
          isFuture: true
        });
      });
    }
    if (this.packingItem.dispatchNotes) {
      notes.push({
        note: this.packingItem.dispatchNotes,
        created: this.packingItem.dispatchNotesLastEditor?.date
          ? utils.convertTimestamp(
              this.packingItem.dispatchNotesLastEditor.date
            )
          : 'Unknown',
        user: {
          id: this.packingItem.dispatchNotesLastEditor.id,
          name: this.packingItem.dispatchNotesLastEditor.name
        }
      });
    }
    this.notes = notes;
  }
};
</script>

<style></style>
