<template>
  <div class="flex flex-col">
    <div
      class="sticky top-0 bg-white z-20 px-8 default-transition"
      v-scroll-shadow
    >
      <div class="flex items-center justify-between">
        <div class="text-xl font-semibold py-5">
          Packing List
          <span class="text-gray-400 font-normal ml-1" v-if="newList"
            >({{ newList.length }})</span
          >
        </div>
        <div class="hidden sm:flex items-center space-x-4">
          <div v-if="selectedList.length > 0" class="text-gray-500">
            {{ selectedList.length }}
            {{ selectedList.length > 1 ? 'items' : 'item' }} selected
          </div>
          <assign-to-packer
            v-if="selectedList.length > 0 && isAdmin"
            :items="selectedList"
            :packers="packers"
            @reassign-packer="reassignPacker"
          />
        </div>
      </div>
      <div
        class="space-x-4 flex justify-between items-center mb-4 overflow-x-auto flex-none"
        v-if="packingList && packingList.length > 0"
      >
        <div
          class="flex space-x-4 flex-none sm:mr-0"
          :class="{ 'pointer-events-none opacity-50': showDuplicates }"
        >
          <radio-buttons
            :options="['all', 'today', 'tomorrow', '🔮']"
            v-model="filters.activeTime"
          />
          <div class="border-r"></div>
          <radio-buttons
            :options="['all', 'subscriptions', 'taster box']"
            v-model="filters.activeFilter"
          />
          <div
            class="flex space-x-4 flex-none"
            v-if="filters.activeFilter === 'taster box'"
          >
            <radio-buttons
              :options="['all', 'adult', 'kitten']"
              v-model="filters.activeType"
            />
          </div>
          <div v-if="isAdmin" class="flex items-center space-x-4 flex-none">
            <div class="border-r h-full"></div>
            <dropdown-button :is-active="filters.activeAssignee">
              <template v-slot:button>
                <div
                  class="flex items-center space-x-2"
                  :class="
                    filters.activeAssignee ? 'text-white' : 'text-gray-400'
                  "
                >
                  <div class="text-gray-500">
                    <div v-if="filters.activeAssignee">
                      <avatar
                        class="flex-none"
                        size="xs"
                        :email="filters.activeAssignee.packername"
                        v-if="filters.activeAssignee.packerid !== 12"
                      />
                      <div v-else class="text-gray-400">
                        <font-awesome-icon :icon="['fal', 'user-slash']" />
                      </div>
                    </div>
                    <font-awesome-icon v-else :icon="['fal', 'users']" />
                  </div>

                  <div>
                    {{
                      filters.activeAssignee
                        ? filters.activeAssignee.packername
                        : 'All Packers'
                    }}
                  </div>
                </div>
              </template>
              <div class="py-2">
                <div class="border-b pb-2 mb-2">
                  <div
                    class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                    @click="setActivePacker(null)"
                  >
                    <div class="text-gray-500">
                      <font-awesome-icon :icon="['fal', 'users']" />
                    </div>
                    <div class="flex-grow truncate">All Packers</div>
                    <div v-if="!filters.activeAssignee">
                      <font-awesome-icon :icon="['fal', 'check']" />
                    </div>
                  </div>
                </div>
                <div
                  v-for="packer in availablePackers"
                  :key="`packer-${packer.packerid}`"
                  class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                  @click="setActivePacker(packer)"
                >
                  <avatar
                    class="flex-none"
                    size="sm"
                    :email="packer.packername"
                    v-if="packer.packerid !== 12"
                  />
                  <div v-else class="text-gray-400">
                    <font-awesome-icon :icon="['fal', 'user-slash']" />
                  </div>

                  <div
                    class="flex-grow truncate"
                    :class="{ 'text-gray-400': packer.packerid === 12 }"
                  >
                    {{ packer.packername }}
                  </div>
                  <div v-if="filters.activeAssignee">
                    <font-awesome-icon
                      v-if="filters.activeAssignee.packerid == packer.packerid"
                      :icon="['fal', 'check']"
                    />
                  </div>
                </div>
              </div>
            </dropdown-button>

            <dropdown-button :is-active="filters.activeShipping">
              <template v-slot:button>
                <div
                  class="flex items-center space-x-2"
                  :class="
                    filters.activeShipping ? 'text-white' : 'text-gray-400'
                  "
                >
                  <div class="text-gray-500">
                    <div v-if="filters.activeShipping">
                      <div class="w-8">
                        <shipped-with
                          override-size
                          is-icon
                          :service="filters.activeShipping"
                        />
                      </div>
                    </div>
                    <font-awesome-icon
                      v-else
                      :icon="['fal', 'truck-ramp-box']"
                    />
                  </div>

                  <div>
                    {{
                      filters.activeShipping
                        ? filters.activeShipping.courier
                        : 'All Couriers'
                    }}
                  </div>
                </div>
              </template>
              <div class="py-2">
                <div class="border-b pb-2 mb-2">
                  <div
                    class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                    @click="setActiveShipping(null)"
                  >
                    <div class="text-gray-500">
                      <font-awesome-icon :icon="['fal', 'truck-ramp-box']" />
                    </div>
                    <div class="flex-grow truncate">All Couriers</div>
                    <div v-if="!filters.activeShipping">
                      <font-awesome-icon :icon="['fal', 'check']" />
                    </div>
                  </div>
                </div>

                <div
                  v-for="shipping in availableShipping"
                  :key="`shipping-${shipping.type}`"
                  class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                  @click="setActiveShipping(shipping)"
                >
                  <div class="w-8">
                    <shipped-with override-size is-icon :service="shipping" />
                  </div>
                  <div class="flex-grow truncate">{{ shipping.courier }}</div>
                  <div v-if="filters.activeShipping">
                    <font-awesome-icon
                      v-if="filters.activeShipping.type == shipping.type"
                      :icon="['fal', 'check']"
                    />
                  </div>
                </div>
              </div>
            </dropdown-button>
            <dropdown-button :is-active="filters.activeCage">
              <template v-slot:button>
                <div
                  class="flex items-center space-x-2"
                  :class="filters.activeCage ? 'text-white' : 'text-gray-400'"
                >
                  <div
                    :class="filters.activeCage ? 'text-white' : 'text-gray-400'"
                  >
                    <font-awesome-icon :icon="['fal', 'pallet-boxes']" />
                  </div>

                  <div>
                    {{
                      filters.activeCage
                        ? getCage(filters.activeCage)
                        : 'All Cages'
                    }}
                  </div>
                </div>
              </template>
              <div class="py-2">
                <div class="border-b pb-2 mb-2">
                  <div
                    class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                    @click="setActiveCage(null)"
                  >
                    <div class="text-gray-500">
                      <font-awesome-icon :icon="['fal', 'pallet-boxes']" />
                    </div>
                    <div class="flex-grow truncate">All Cages</div>
                    <div v-if="!filters.activeCage">
                      <font-awesome-icon :icon="['fal', 'check']" />
                    </div>
                  </div>
                </div>

                <div
                  v-for="cage in availableCages"
                  :key="`cage-${cage}`"
                  class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                  @click="setActiveCage(cage)"
                >
                  <div class="flex-grow truncate">{{ getCage(cage) }}</div>
                  <div v-if="filters.activeCage">
                    <font-awesome-icon
                      v-if="filters.activeCage == cage"
                      :icon="['fal', 'check']"
                    />
                  </div>
                </div>
              </div>
            </dropdown-button>
          </div>
        </div>
        <div class="self-end flex space-x-2 items-center">
          <div
            v-if="duplicatesList && duplicatesList.length > 0"
            class="py-1 px-3 rounded flex-none cursor-pointer flex space-x-2 items-center"
            @click="showDuplicates = !showDuplicates"
            :class="{
              'bg-red-400 text-white': showDuplicates,
              'text-red-400 hover:bg-red-100 ': !showDuplicates
            }"
          >
            <font-awesome-icon
              fixed-width
              :icon="['fad', 'triangle-exclamation']"
            />
            <div>
              {{ duplicatesList.length }}
              {{ duplicatesList.length === 1 ? 'Duplicate' : 'Duplicates' }}
            </div>
          </div>
          <div
            v-if="holdList && holdList.length > 0"
            class="py-1 px-3 rounded flex-none cursor-pointer flex space-x-2 items-center"
            @click="showHoldList = !showHoldList"
            :class="{
              'bg-yellow-100 text-gray-700 ': showHoldList,
              'text-yellow-500 hover:bg-yellow-100 ': !showHoldList
            }"
          >
            <font-awesome-icon fixed-width :icon="['fal', 'hand']" />
            <div>
              {{ holdList.length }}
              On Hold
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative flex flex-col flex-grow -mr-0 sm:mr-0 px-6">
      <loading
        :is-loading="isLoading && !newList"
        is-full
        class="flex-grow flex flex-col"
      >
        <div class="flex-grow w-full overflow-x-auto">
          <div v-if="newList && newList.length > 0">
            <div
              class="grid auto-cols-min whitespace-nowrap"
              :class="isAdmin ? 'grid-cols-6' : 'grid-cols-5'"
            >
              <div class="table-head contents">
                <div
                  class="flex justify-center w-10 px-4 sm:px-0 whitespace-nowrap min-w-min"
                >
                  <input type="checkbox" v-model="selectAll" />
                </div>
                <div></div>
                <div>Type</div>
                <div v-if="isAdmin">Assigned To</div>
                <div>Created</div>
                <div>Dispatch</div>
                <div></div>
                <div></div>
              </div>
              <div
                v-for="(pack, index) in newList"
                :key="`pack-${index}`"
                class="group flex items-center py-2 hover:bg-gray-50 contents table-item"
                :class="{
                  'is-selected': pack.isSelected,
                  'is-outstanding': dateBefore(pack.dispatchAt),
                  'is-on-hold': pack.isOnHold
                }"
              >
                <div class="flex justify-center w-10">
                  <input
                    type="checkbox"
                    v-model="pack.isSelected"
                    :disabled="pack.isOnHold"
                  />
                </div>
                <div>
                  <router-link
                    :to="{
                      name: 'packingProfile',
                      params: { id: pack.dispatchid }
                    }"
                    class="flex w-full group cursor-pointer items-center justify-between"
                  >
                    <div class="flex space-x-2 flex-grow items-center pr-4">
                      <div
                        class="w-36 text-center rounded"
                        :class="statusClass(pack.dispatchAt)"
                      >
                        #{{ pack.invId }}
                      </div>
                      <div class="flex-grow truncate">
                        {{ pack.name }}
                      </div>
                      <div
                        v-if="pack.notes || pack.hasFutureNote"
                        class="flex items-center relative"
                      >
                        <div
                          class="w-5 h-4 transform rotate-6 rounded bg-yellow-200 absolute top-1"
                        ></div>
                        <font-awesome-icon
                          :icon="['fal', 'message-lines']"
                          fixed-width
                          class="transform relative rotate-12 text-lg top-1 text-gray-700"
                        />
                      </div>

                      <status-label
                        v-if="pack.isOnHold"
                        label="On Hold"
                        color="yellow"
                      />
                    </div>
                  </router-link>
                </div>
                <div class="flex items-center flex-grow">
                  <div class="flex-grow flex items-center space-x-1">
                    <div>
                      {{ pack.isSubscription ? 'Subscription' : 'Taster Box' }}
                    </div>
                  </div>

                  <div v-if="!pack.isSubscription" class="mr-4 flex space-x-2">
                    <status-label
                      v-if="pack.isManualOrder"
                      label="M"
                      tooltip="Manual Order"
                      color="blue"
                    />

                    <status-label
                      v-if="pack.containsSampleKitten"
                      label="K"
                      tooltip="Kitten"
                      color="pink"
                    />
                  </div>
                  <div class="mr-4 flex space-x-2" v-else>
                    <status-label
                      v-if="pack.isManualOrder"
                      label="M"
                      tooltip="Manual Order"
                      color="blue"
                    />
                    <status-label
                      v-if="pack.isFirstMonth"
                      label="N"
                      tooltip="First Month"
                      color="green"
                    />

                    <status-label
                      v-if="!pack.isFirstMonth && !pack.isManualOrder"
                      label="R"
                      tooltip="Renewal"
                      color="indigo"
                    />
                    <status-label
                      v-if="pack.isComeBackFirstMonth && isAdmin"
                      label="C"
                      tooltip="Back from cancel"
                      color="red"
                    />
                  </div>
                </div>
                <div class="truncate py-0" v-if="isAdmin">
                  <div class="w-full flex items-center">
                    <div
                      class="flex"
                      :class="{
                        'text-gray-400': pack.assignedTo.packerid === 12
                      }"
                      v-if="!isAdmin"
                    >
                      <avatar
                        class="mr-2 flex-none"
                        size="sm"
                        :email="pack.assignedTo.packername"
                        v-if="pack.assignedTo.packerid !== 12"
                      />
                      <div v-else>
                        <font-awesome-icon :icon="['fal', 'user-slash']" />
                      </div>
                      {{ pack.assignedTo.packername }}
                    </div>

                    <dropdown-button is-full class="mr-4" camo v-else>
                      <template v-slot:button>
                        <div class="flex items-center w-full space-x-2">
                          <div
                            class="text-gray-500"
                            v-if="pack.assignedTo.packerid !== 12"
                          >
                            <avatar
                              class="flex-none"
                              size="sm"
                              :key="pack.assignedTo.packername"
                              :email="pack.assignedTo.packername"
                            />
                          </div>
                          <div v-else class="text-gray-400">
                            <font-awesome-icon :icon="['fal', 'user-slash']" />
                          </div>
                          <div
                            class="text-black"
                            :class="{
                              'text-gray-400': pack.assignedTo.packerid === 12
                            }"
                          >
                            {{ pack.assignedTo.packername }}
                          </div>
                        </div>
                      </template>
                      <div class="py-2">
                        <div class="pt-1 pb-1 mb-2 text-gray-400 px-4 italic">
                          Assign to...
                        </div>
                        <div
                          v-for="packer in activePackers"
                          :key="`packer-${packer.id}`"
                          class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                          @click="
                            reassignPacker({
                              pack,
                              packer
                            })
                          "
                          :class="
                            pack.assignedTo.packerid === packer.id
                              ? 'pointer-events-none'
                              : 'cursor-pointer'
                          "
                        >
                          <avatar
                            class="flex-none"
                            size="sm"
                            :email="packer.name"
                            v-if="packer.id !== 12"
                          />
                          <div v-else class="text-gray-400">
                            <font-awesome-icon :icon="['fal', 'user-slash']" />
                          </div>

                          <div
                            class="flex-grow truncate"
                            :class="{
                              'text-gray-400': packer.id === 12
                            }"
                          >
                            {{ packer.name }}
                          </div>
                          <div v-if="pack.assignedTo">
                            <font-awesome-icon
                              v-if="pack.assignedTo.packerid == packer.id"
                              :icon="['fal', 'check']"
                            />
                          </div>
                        </div>
                      </div>
                    </dropdown-button>
                  </div>
                </div>
                <div>
                  {{ pack.purchasedAt | formatDate('D MMM, YYYY') }}
                  <span class="text-gray-500 ml-1">{{
                    pack.purchasedAt | formatDate('h:mm a')
                  }}</span>
                </div>
                <div :class="{ 'text-red-500': dateBefore(pack.dispatchAt) }">
                  {{ pack.dispatchAt | formatDate('D MMM, YYYY') }}
                </div>
                <div class="flex justify-center">
                  <shipped-with is-icon :service="pack.shippingType" />
                </div>
                <div class="flex justify-center">
                  <status-label
                    :label="getCage(pack.cage)"
                    :tooltip="getCage(pack.cage)"
                    color="gray"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            v-else
            class="flex-grow h-full flex items-center justify-center text-gray-400"
          >
            <div>
              <div class="mb-3 mx-auto w-24 h-24 text-4xl text-center">
                <img src="images/success.gif" />
              </div>
              Whooo... all done, nothing left to pack!
            </div>
          </div>
        </div>
      </loading>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import Loading from '@/components/common/ui/Loading';
import RadioButtons from '@/components/common/ui/RadioButtons';
import DropdownButton from '@/components/common/ui/DropdownButton';

import StatusLabel from '@/components/common/ui/ColorLabel';
import Avatar from '@/components/common/ui/Avatar';
import { orderBy, uniqBy, groupBy, forOwn } from 'lodash/fp';
import { mapGetters } from 'vuex';

import AssignToPacker from '@/components/common/ui/assign/AssignToPacker';
import ShippedWith from '../../components/common/ui/ShippedWith.vue';
import {
  isSameDay,
  endOfToday,
  addDays,
  endOfDay,
  isBefore,
  isAfter
} from 'date-fns';

export default {
  metaInfo() {
    return {
      titleTemplate: `Packing (${this.packingCount}) - CCC Admin`
    };
  },
  components: {
    Avatar,
    DropdownButton,

    AssignToPacker,
    Loading,
    RadioButtons,
    StatusLabel,
    ShippedWith
  },
  watch: {
    selectAll(newValue) {
      this.packingList.forEach(i => {
        this.$set(i, 'isSelected', newValue);
      });
    },
    showDuplicates() {
      this.filterTheList();
    },
    showHoldList() {
      this.filterTheList();
    },
    filters: {
      deep: true,

      handler(newValue) {
        utils.setStorage('packingListFilters', newValue, 'session');
        if (newValue.activeFilter !== 'taster box') {
          newValue.activeType = 'all';
        }
        this.filterTheList();
      }
    }
  },
  data() {
    return {
      isLoading: true,
      selectAll: false,
      filters: utils.getStorage('packingListFilters', 'session') || {
        activeFilter: 'all',
        activeTime: 'today',
        activeType: 'all',
        activeCage: null,
        activeAssignee: null,
        activeShipping: null
      },
      showDuplicates: false,
      showHoldList: false,
      scrolled: false,
      newList: null,
      duplicatesList: null,
      holdList: null
    };
  },
  computed: {
    ...mapGetters({
      packingCount: 'getPackingCount',
      packingList: 'getPackingList',
      isAdmin: 'isAdmin',
      packers: 'packersGetter',
      activePackers: 'activePackersGetter'
    }),
    availablePackers() {
      const packers = [];
      this.packingList.forEach(p => {
        packers.push(p.assignedTo);
      });

      return orderBy(['packername'], ['asc'], uniqBy('packerid', packers));
    },
    availableShipping() {
      const shipping = [];
      this.packingList.forEach(p => {
        shipping.push(p.shippingType);
      });

      return orderBy(['courier'], ['asc'], uniqBy('type', shipping));
    },
    availableCages() {
      const cages = [];
      this.packingList.forEach(p => {
        // check if p.cage is undefined

        cages.push(p.cage);
      });

      //order by alphabetically and uniq by cage using pure javascript
      return cages
        .sort()
        .filter(function (item, pos, ary) {
          return !pos || item != ary[pos - 1];
        })
        .filter(c => c !== undefined);
    },
    selectedList() {
      if (this.newList) {
        return this.newList.filter(l => l.isSelected);
      }
      return [];
    }
  },
  methods: {
    setActivePacker(packer) {
      this.filters.activeAssignee = packer;
    },
    getCage(cage) {
      const maps = {
        '24_hour': '24Hr',
        '48_hour': '48Hr',
        dpd: 'DPD',
        yodel: 'YDL',
        unknown: '?'
      };
      return cage ? maps[cage] : '?';
    },
    setActiveShipping(shipping) {
      this.filters.activeShipping = shipping;
    },
    setActiveCage(cage) {
      this.filters.activeCage = cage;
    },

    getPackingList() {
      this.$store.dispatch('getPackingList').then(() => {
        this.isLoading = false;
      });
    },

    dateBefore(date) {
      return utils.dateBefore(date);
    },
    statusClass(date) {
      if (this.dateBefore(date)) {
        return 'text-red-500 bg-red-50 ';
      }
      return 'text-gray-400';
    },
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    reassignPacker(payload) {
      this.$store
        .dispatch('reassignPacker', {
          deliveryId: payload.pack.dispatchid,
          packerId: payload.packer.id
        })
        .then(() => {
          const assigned = this.packingList.find(
            i => i.dispatchid === payload.pack.dispatchid
          );

          if (assigned) {
            assigned.assignedTo = {
              packerid: payload.packer.id,
              packername: payload.packer.name
            };

            assigned.fl_assigned = payload.packer.id;
            assigned.isSelected = false;
          }
          if (this.selectAll) {
            this.selectAll = false;
          }
        });
    },
    getTimeFilter(time) {
      const date = new Date(time);
      const today = endOfToday();
      const tomorrow = endOfDay(addDays(today, 1));

      if (isSameDay(date, today) || isBefore(date, today)) {
        return 'today';
      } else if (isSameDay(date, tomorrow)) {
        return 'tomorrow';
      } else if (isAfter(date, tomorrow)) {
        return '🔮';
      } else {
        throw new Error('Provided date was invalid');
      }
    },
    filterTheList() {
      var filter = {
        fl_time: this.filters.activeTime,
        fl_courier: this.filters.activeShipping
          ? this.filters.activeShipping?.type
          : this.availableShipping.map(s => s.type),
        fl_assigned: this.filters.activeAssignee
          ? this.filters.activeAssignee?.packerid
          : this.availablePackers.map(p => p.packerid),
        fl_type:
          this.filters.activeFilter === 'all'
            ? ['taster box', 'subscriptions']
            : this.filters.activeFilter === 'subscriptions'
              ? 'subscriptions'
              : 'taster box',
        fl_cat_type:
          this.filters.activeType === 'all'
            ? ['adult', 'kitten']
            : this.filters.activeType === 'adult'
              ? 'adult'
              : 'kitten',
        fl_cage: this.filters.activeCage
          ? this.filters.activeCage
          : this.availableCages.map(p => p)
      };

      if (this.showDuplicates) {
        const list = [];
        this.duplicatesList.forEach(d => list.push(...d.items));
        this.newList = orderBy(['userid', 'dispatchAt'], ['asc', 'asc'], list);
      } else if (this.showHoldList) {
        this.newList = orderBy(['purchasedAt'], ['asc'], this.holdList);
      } else {
        let sort = ['isFirstMonth', 'priority', 'dispatchAt'];
        let order = ['desc', 'desc', 'asc'];
        if (this.filters.activeTime === 'all') {
          filter.fl_time = ['today', 'tomorrow', '🔮'];
          sort = ['purchasedAt', 'dispatchAt'];
          order = ['asc', 'asc'];
        }
        this.newList = this.packingList.filter(item => {
          return Object.keys(filter).every(key => {
            if (filter[key] instanceof Array) {
              return filter[key].includes(item[key]);
            } else {
              return filter[key] === item[key];
            }
          });
        });
        this.newList = orderBy(sort, order, this.newList);
      }
    },
    setDuplicates() {
      const duplicates = [];

      forOwn(
        function (value) {
          let user = { id: value[0].userid, name: value[0].name, items: value };
          if (user.items.length > 0) {
            duplicates.push(user);
          }
        },
        groupBy('userid', this.packingList)
      );
      this.duplicatesList = duplicates.filter(d => d.items.length > 1);
    },
    setHoldList() {
      this.holdList = this.packingList.filter(d => d.isOnHold);
    }
  },
  mounted() {
    this.$store
      .dispatch('getPackingList')
      .then(() => {
        this.packingList.map(pack => {
          pack.fl_time = this.getTimeFilter(pack.dispatchAt);
          pack.fl_courier = pack.shippingType?.type;
          pack.fl_type = pack.isSubscription ? 'subscriptions' : 'taster box';
          pack.fl_assigned = pack.assignedTo?.packerid;
          pack.fl_cat_type = pack.containsSampleKitten ? 'kitten' : 'adult';
          pack.fl_cage = pack.cage ? pack.cage : 'unknown';
          this.$set(pack, 'isSelected', false);
        });
        this.setDuplicates();
        this.setHoldList();
        this.filterTheList();
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="scss" scoped>
.grid {
  &.grid-cols-6 {
    grid-template-columns:
      max-content
      auto minmax(min(200px, 200px), max-content)
      minmax(min(200px, 200px), max-content) minmax(
        min(220px, 220px),
        max-content
      )
      minmax(min(120px, 120px), max-content) minmax(
        min(80px, 80px),
        max-content
      )
      minmax(min(80px, 80px), max-content);
  }
  &.grid-cols-5 {
    grid-template-columns:
      max-content
      auto minmax(min(200px, 200px), max-content)
      minmax(min(220px, 220px), max-content)
      minmax(min(120px, 120px), max-content) minmax(
        min(80px, 80px),
        max-content
      )
      minmax(min(80px, 80px), max-content);
  }
  .table-item > * {
    @apply whitespace-nowrap min-w-min px-4 sm:px-0;
  }
  // grid-template-columns: 4fr 2fr 2fr 2fr 2fr;
}
</style>
