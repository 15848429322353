<template>
  <div class="border-b  flex items-center p-2 px-4 space-x-4 ">
    <div
      @click="goHome"
      class="text-gray-500 text-2xl w-11 h-11 hover:bg-gray-100 cursor-pointer rounded flex items-center justify-center"
    >
      <font-awesome-icon :icon="['fal', 'house-user']" />
    </div>
    <div class="flex-grow text-gray-500 text-base flex items-center space-x-4">
      <div class="flex space-x-2 items-center">
        <span class="text-gray-900 text-xl">{{ currentIndex + 1 }}</span>
        <span>/ {{ packingProgress.items[itemType].length }}</span>
      </div>
      <div class="w-32">
        <progress-bar
          :max="packingProgress.items[itemType].length"
          :current="currentIndex + 1"
        />
      </div>
    </div>

    <div>
      <div
        class="rounded bg-gray-100 cursor-pointer hover:bg-gray-200 w-full py-3 px-4"
        @click="next"
      >
        {{ isLast ? 'Finish' : 'Next' }} &rarr;
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import ProgressBar from '@/components/common/ui/ProgressBar.vue';
export default {
  components: { ProgressBar },
  name: 'PackingProgress',
  computed: {
    ...mapGetters({
      packingProgress: 'packingProgressGetter'
    }),
    itemType() {
      return this.$route.query.type;
    },
    currentIndex() {
      return this.packingProgress.items[this.itemType].findIndex(
        i => i.dispatchid === this.$route.params.id.toString()
      );
    },
    nextPackingItem() {
      if (!this.isLast) {
        const next = this.packingProgress.items[this.itemType][
          this.currentIndex + 1
        ].dispatchid;
        this.setNextItem(next);
        return next;
      }

      this.setNextItem(null);
      return null;
    },
    isLast() {
      return (
        this.currentIndex ===
        this.packingProgress.items[this.itemType].length - 1
      );
    }
  },
  methods: {
    ...mapMutations({
      setNextItem: 'set_next_item'
    }),
    next() {
      if (this.isLast) {
        this.$router.push({ name: 'dashboard' });
      } else {
        this.$router.push({
          name: 'packingProfile',
          params: { id: this.nextPackingItem },
          query: { type: this.itemType }
        });
      }
    },
    goHome() {
      this.$router.push({ name: 'dashboard' });
    }
  }
};
</script>

<style></style>
