<template>
  <div class="text-base space-x-2 flex w-full">
    <form
      @submit.prevent="
        setModal(
          packingItem.dispatchNotes ? 'Update note...' : 'Create note...',
          'create-note',
          'note'
        )
      "
      class="flex w-full"
    >
      <loading-button secondary is-full class="flex-grow sm:flex-grow-0"
        ><div class="flex items-center space-x-2">
          <font-awesome-icon :icon="['fal', 'message-lines']" fixed-width />
          <div>
            {{
              packingItem.dispatchNotes ? 'Update note...' : 'Create note...'
            }}
          </div>
        </div></loading-button
      >
    </form>
    <!-- <loading-button secondary stretch tooltip="Hold order"
      ><font-awesome-icon :icon="['fal', 'pause-circle']" fixed-width
    /></loading-button> -->
    <dropdown-button no-dropdown stretch width="w-48">
      <template v-slot:button>
        <div class="">
          <font-awesome-icon
            :icon="['fal', 'ellipsis']"
            class="text-gray-400 text-lg"
          />
        </div>
      </template>
      <div class="py-2">
        <div
          class="p-3 py-3 flex items-center leading-none space-x-2 hover:bg-gray-100 cursor-pointer"
          @click="holdOrder"
        >
          <div class="w-5 flex justify-center">
            <font-awesome-icon
              :icon="['fal', 'hand']"
              class="text-gray-400 text-lg"
            />
          </div>
          <div>
            {{ packingItem.isOnHold ? 'Remove hold' : 'Hold order' }}
          </div>
        </div>
        <div
          class="p-3 py-3 flex items-center leading-none space-x-2 hover:bg-red-50 cursor-pointer "
          @click="setModal('Cancel order...', 'cancel-order', 'cancel')"
        >
          <div class="w-5 flex justify-center">
            <font-awesome-icon
              :icon="['fal', 'ban']"
              class="text-red-400 text-lg"
            />
          </div>
          <div>
            Cancel order
          </div>
        </div>
      </div>
    </dropdown-button>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      :id="activeModal.id"
      :modal-title="activeModal.title"
    >
      <div v-if="modalVisible">
        <notes-input
          v-if="activeModal.name === 'note'"
          placeholder="Add a note to this order..."
          :endpoint="links.packersDispatchnotes"
          is-modal
          note-type="order"
          :note="packingItem.dispatchNotes"
        />

        <div v-if="activeModal.name === 'cancel'" class="space-y-4">
          <p>Are you sure you want to cancel order #{{ orderId }}?</p>
          <div>
            <textarea
              v-model="reason"
              placeholder="Please enter a reason for cancelling this order"
              class="max-w-full"
            ></textarea>
          </div>
          <div class="flex  space-x-4 mt-8">
            <form @submit.prevent="setAction(links.packersRemove)">
              <loading-button
                warning
                :is-loading="isLoading"
                :is-enabled="hasReason"
                :label="`Yes, cancel order`"
              />
            </form>
            <form @submit.prevent="toggleModal">
              <loading-button label="Cancel" secondary />
            </form>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import LoadingButton from '@/components/common/ui/LoadingButton';
import NotesInput from '@/components/common/ui/NotesInput';
import Modal from '@/components/common/ui/Modal';
import utils from '@/utils';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isEmpty } from 'lodash/fp';
import DropdownButton from '../DropdownButton.vue';
export default {
  props: {
    links: {
      type: Object
    },
    orderId: {
      type: String
    }
  },
  components: {
    Modal,
    NotesInput,
    LoadingButton,
    DropdownButton
  },
  data() {
    return {
      activeModal: {
        title: '',
        id: 'create-note',
        name: ''
      },
      reason: ''
    };
  },
  computed: {
    ...mapGetters({
      modalVisible: 'getModalVisible',
      packingItem: 'currentPackingItemGetter'
    }),
    hasReason() {
      return !isEmpty(this.reason);
    }
  },
  methods: {
    ...mapActions({
      setPackingHold: 'setPackingHold'
    }),
    ...mapMutations({
      updateItem: 'update_packing_item_key'
    }),
    setAction(action) {
      this.$store
        .dispatch('packingAction', { url: action, reason: this.reason })
        .then(() => {
          this.toggleModal();
        });
    },
    setModal(title, id, name) {
      this.activeModal.title = title;
      this.activeModal.id = id;
      this.activeModal.name = name;
      this.toggleModal();
    },
    toggleModal() {
      utils.toggleModal();
    },
    holdOrder() {
      this.setPackingHold({
        id: this.$route.params.id,
        status: this.packingItem.isOnHold ? 'ready' : 'hold'
      }).then(response => {
        console.log(response);
        this.updateItem({
          key: 'isOnHold',
          payload: this.packingItem.isOnHold ? false : true
        });
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
