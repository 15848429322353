<template>
  <div class="text-lg font-semibold">
    <div
      class="text-lg flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 items-center pr-4 px-4 py-4"
    >
      <div class="flex-grow flex space-x-4 items-center sm:justify-end">
        <div
          class="border flex flex-col w-full sm:flex-row flex-grow sm:space-y-0 sm:space-x-2 text-lg divide-y sm:divide-y-0 sm:divide-x rounded sm:h-16"
        >
          <div
            v-if="boxSize.totalBoxes > 0"
            class="p-4 w-full sm:w-1/2 text-2xl justify-center leading-none h-12 sm:h-auto sm:py-2 flex space-x-2 items-center"
          >
            <div class="flex flex-col sm:space-y-2">
              <div
                v-for="(box, index) in boxSize.boxes"
                :key="`box-${index}`"
                class="flex items-center"
              >
                <div class="pr-2">
                  <font-awesome-icon
                    :icon="['fal', 'box-open']"
                    class="text-gray-600"
                  />
                </div>
                <span
                  class="text-gray-500 flex items-center text-base font-normal leading-none"
                  >{{ box.total }}
                  <div class="px-2">
                    <font-awesome-icon
                      :icon="['fal', 'xmark']"
                      class="text-gray-500 text-sm"
                    /></div
                ></span>
                {{ box.name }}
              </div>
            </div>
          </div>

          <div
            class="flex w-full flex-none items-center justify-center px-4 h-12 sm:h-auto"
            :class="boxSize.totalBoxes > 0 ? 'sm:w-1/2' : 'w-full'"
          >
            <div class="h-7 flex">
              <shipped-with
                is-icon
                override-size
                :service="subscription.shippingType"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <packing-notes :uid="uid" />

    <div
      class="flex flex-col justify-between"
      v-for="(group, index) in groupedItems"
      :key="`${group.key}-${index}`"
      v-show="group.items.length > 0"
    >
      <div class="flex justify-between">
        <div class="px-4">
          {{ group.label }}
        </div>
        <div
          class="px-4 text-2xl"
          :class="groupedCount[group.key] ? 'text-green-400' : 'text-gray-300'"
        >
          <font-awesome-icon
            :icon="[groupedCount[group.key] ? 'fad' : 'fal', 'box-check']"
          />
        </div>
      </div>

      <div
        class="flex my-4 border-t pt-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 p-4"
        :class="{
          'pointer-events-none': isPacked,
          'opacity-50 pointer-events-none': isHold
        }"
      >
        <packing-item
          v-for="(item, idx) in group.items"
          :key="`item-${item.sku}-${idx}`"
          :item="item"
          class="w-full"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PackingItem from '@/components/common/ui/packing/PackingItem';
import PackingNotes from '@/components/common/ui/packing/PackingNotes';
import utils from '@/utils';
import { skuOrder } from '@/const';
import {
  find,
  cloneDeep,
  includes,
  isEmpty,
  filter,
  without,
  clone,
  differenceBy
} from 'lodash/fp';
import { mapGetters } from 'vuex';
import ShippedWith from '../ShippedWith.vue';
export default {
  components: {
    PackingItem,
    PackingNotes,

    ShippedWith
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    bonusItems: {
      type: Array,
      default: () => []
    },
    subscription: {
      type: Object,
      required: true
    },
    isPacked: {
      type: Boolean,
      default: false
    },
    uid: {
      type: String
    },
    flyers: {
      type: Array,
      default: () => []
    },
    isHold: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      groups: [
        { label: 'Free Samples', key: 'FREE', items: [] },
        { label: 'Flyers', key: '_FLYER_', items: [] },
        { label: 'Pouches', key: '_PCH_', items: [] },
        { label: 'Trays', key: '_TRA_', items: [] },
        { label: 'Cans', key: '_CAN_', items: [] },
        { label: 'Dry Food', key: '_BAG_', items: [] },
        {
          label: this.subscription.isSample ? 'Taster Box' : 'Taster Flavours',
          key: '_TST_',
          items: []
        }
      ],
      groupedItems: []
    };
  },
  mounted() {
    const tb = {
      name: `Taster Box`,
      sku: 'T_TST_ADT',
      quantity: 1,
      increments: 1,
      total: 1,
      isCompleted: this.isPacked ? true : false
    };

    const arr = find(['key', '_TST_'], this.groups);

    if (this.subscription.isSample) {
      this.subscription.cats.forEach(c => {
        if (c.parentSkUs) {
          c.parentSkUs.forEach(p => {
            const name = find(['sku', p], this.products).name;
            const description = find(['sku', p], this.products).description;
            arr.items.push({
              name: name,
              description: description,
              sku: p,
              quantity: 1,
              increments: 1,
              total: 1,
              isCompleted: this.isPacked ? true : false
            });
          });
        } else {
          let tasterBox = clone(tb);
          if (c.isKitten) {
            tasterBox.name = 'Taster Box (KITTEN)';
            tasterBox.sku = 'T_TST_KIT';
          }
          arr.items.push(tasterBox);
        }
      });
    }
    if (this.subscription.firstMonth) {
      if (!this.justKittens.isKitten) {
        const inSub = [];
        const avProd = [];
        this.totalAmounts.forEach(a => {
          inSub.push(a.sku);
        });

        this.availableProducts.forEach(av => {
          avProd.push(av.sku);
        });
        const items = without(inSub, avProd);

        items.forEach(i => {
          const name = find(['sku', i], this.products).name;
          arr.items.push({
            name: name,
            sku: i,
            quantity: 1,
            increments: 1,
            total: 1,
            isCompleted: this.isPacked ? true : false
          });
        });
      } else {
        this.justKittens.items.forEach(i => {
          arr.items.push({
            name: i.name,
            sku: i.sku,
            quantity: 1,
            increments: 1,
            total: includes('_BAG_', i.sku) ? i.total : 1,
            isCompleted: this.isPacked ? true : false
          });
        });
      }
    }

    if (this.subscription.hadSamplePack && !this.subscription.isSample) {
      arr.items = [];
    }

    this.groupItems();
  },

  computed: {
    ...mapGetters({
      products: 'getProducts',
      isAdmin: 'isAdmin',
      boxSize: 'currentPackingBoxGetter'
    }),

    totalAmounts() {
      const totals = [];

      this.list.forEach(i => {
        if (i.active) {
          i.products.forEach(p => {
            if (find(['sku', p.sku], totals)) {
              const sku = find(['sku', p.sku], totals);
              sku.total += p.total;
              sku.quantity += p.quantity;
            } else {
              totals.push(cloneDeep(p));
            }
          });
        }
      });
      this.bonusItems.forEach(p => {
        totals.push(cloneDeep({ ...p, ...{ isBonus: true } }));
      });
      this.flyers.forEach(p => {
        totals.push(cloneDeep({ ...p, ...{ isFlyer: true } }));
      });

      totals.forEach(p => {
        this.$set(p, 'isCompleted', this.isPacked ? true : false);
      });
      return totals;
    },

    groupedCount() {
      const count = {};

      this.groupedItems.forEach(i => {
        count[i.key] = isEmpty(filter(['isCompleted', false], i.items));
      });

      this.$store.commit('update_packing_checklist', {
        type: 'I_PB',
        value: Object.keys(count).every(k => count[k])
      });

      this.$store.commit('update_packing_checklist', {
        type: 'I_TB',
        value: Object.keys(count).every(k => count[k])
      });

      return count;
    },
    availableProducts() {
      const products = filter(['available', true], this.products);

      return filter(function (o) {
        return (
          (includes('_PCH_', o.sku) ||
            includes('_TRA_', o.sku) ||
            includes('_CAN_', o.sku)) &&
          !includes('_KIT', o.sku)
        );
      }, products);
    },
    justKittens() {
      const k = { isKitten: false, items: [] };
      const prods = filter(function (o) {
        return !includes('_BAG', o.sku);
      }, this.totalAmounts);
      const kittenProds = filter(function (o) {
        return includes('_KIT', o.sku) && !includes('_TST_', o.sku);
      }, this.products);

      const kitten = filter(function (o) {
        return includes('_KIT', o.sku);
      }, prods);

      const tasters = differenceBy('sku', kittenProds, kitten);

      if (!isEmpty(kitten)) {
        if (prods.length === kitten.length) {
          k.items = tasters;
          if (
            !find(function (o) {
              return includes('D_BAG_CHK', o.sku);
            }, this.totalAmounts)
          ) {
            k.items.push({
              name: 'Chicken',
              sku: 'D_BAG_CHK',
              quantity: 1,
              increments: 20,
              total: 20,
              isCompleted: this.isPacked ? true : false
            });
          }
          k.isKitten = true;
          return k;
        } else {
          return k;
        }
      }
      return k;
    }
  },
  methods: {
    hasBonus(pack) {
      return !isEmpty(pack.bonus.products);
    },
    calculateWeight(cats, noFormat) {
      const needsTaster = this.subscription.isSample;
      return utils.calculateWeight(cats, needsTaster, noFormat);
    },
    groupItems() {
      console.log('grouping items');
      if (!this.subscription.isSample) {
        console.log('not a sample');
        // order the totalAmounts array by the skuOrder array
        this.totalAmounts.sort((a, b) => {
          return skuOrder.indexOf(a.sku) - skuOrder.indexOf(b.sku);
        });

        this.totalAmounts.forEach(i => {
          find(function (o) {
            return includes(o.key, i.sku) || i.isBonus;
          }, this.groups).items.push(i);
        });

        const group = this.groups;
        const order = [
          '_FLYER_',
          '_CAN_',
          '_PCH_',
          '_TRA_',
          '_BAG_',
          '_TST_',
          '_KIT_',
          'FREE'
        ];

        // return the groups array ordered by the order array
        this.groupedItems = group.sort((a, b) => {
          return order.indexOf(a.key) - order.indexOf(b.key);
        });
      } else {
        // for everything in the _TST_ group could how many of each sku there are and group them
        const tasters = find(['key', '_TST_'], this.groups);
        const tastersGrouped = [];
        tasters.items.forEach(i => {
          const sku = i.sku;
          const name = i.name;
          const quantity = i.quantity;
          const increments = i.increments;
          const total = i.total;
          const isCompleted = i.isCompleted;

          if (find(['sku', sku], tastersGrouped)) {
            const t = find(['sku', sku], tastersGrouped);
            t.total += total;
            t.quantity += quantity;
          } else {
            tastersGrouped.push({
              name: name,
              sku: sku,
              quantity: quantity,
              increments: increments,
              total: total,
              isCompleted: isCompleted
            });
          }
        });

        tasters.items = tastersGrouped;

        console.log('tasters', tasters.items);
      }

      this.groupedItems = this.groups;
    }
  }
};
</script>

<style lang="css" scoped></style>
