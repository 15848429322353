<template>
  <div class="h-full flex flex-col">
    <packing-progress v-if="!isAdmin" />
    <div class="flex flex-col sm:flex-row flex-grow sm:overflow-hidden">
      <div class="flex-grow overflow-y-auto relative">
        <loading :is-loading="isLoading" is-full class="w-full">
          <div
            v-if="packingItem && user"
            :key="`content-${packingItem.invId}`"
            class="w-full"
          >
            <div
              class="bg-yellow-100 p-6 flex text-gray-700 items-center space-x-4"
              v-if="packingItem.isOnHold"
            >
              <font-awesome-icon :icon="['fal', 'hand']" class="text-2xl" />
              <div>
                This packing item is on hold and cannot be packed at this time
              </div>
            </div>
            <div
              class="text-xl border-b p-4 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 space-x-2 justify-between"
            >
              <div
                class="font-medium flex flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-2 items-center"
              >
                <div
                  class="hidden sm:visible text-gray-400 flex-none"
                  :to="{ name: 'packing' }"
                >
                  {{ isPacked ? 'Shipping' : 'Packing' }} Item /
                </div>

                <span class="truncate">{{ packingName }} </span>
                <div class="flex space-x-2 flex-grow">
                  <status-label
                    v-if="packingItem.isManualOrder"
                    label="M"
                    tooltip="Manual Order"
                    color="blue"
                  />
                  <div
                    class="flex space-x-2 flex-grow"
                    v-if="packingItem.isSubscription"
                  >
                    <status-label
                      v-if="hasBonus(packingItem)"
                      label="S"
                      tooltip="Free Samples"
                      color="yellow"
                    />

                    <status-label
                      v-if="packingItem.firstMonth"
                      label="N"
                      tooltip="First Month"
                      color="green"
                    />
                    <status-label
                      v-if="
                        !packingItem.firstMonth && !packingItem.isManualOrder
                      "
                      label="R"
                      tooltip="Renewal"
                      color="indigo"
                    />
                    <status-label
                      v-if="packingItem.comeBack && isAdmin"
                      label="C"
                      tooltip="Back from cancel"
                      color="red"
                    />
                  </div>
                </div>
              </div>
              <div class="text-2xl w-full sm:w-auto" v-if="isAdmin">
                <packing-actions
                  :links="packingLinks"
                  :orderId="packingItem.invId"
                />
              </div>
            </div>
            <packing-list
              v-if="packingItem"
              :list="packingItem.cats"
              :flyers="activeFlyers"
              :bonus-items="bonusItems"
              :is-packed="isPacked"
              :is-hold="packingItem.isOnHold"
              :subscription="packingItem"
              :uid="getUserId(links.packersUser)"
            />
          </div>
        </loading>
      </div>
      <div
        class="text-left overflow-y-auto relative sidebar flex-none border-l bg-gray-50"
      >
        <loading :is-loading="isLoading" is-full class="w-full">
          <div
            v-if="packingItem && user"
            :key="`sidebar-${packingItem.invId}`"
            class="w-full"
          >
            <div class="flex flex-col justify-between card-section">
              <router-link
                :to="{
                  name: 'userProfile',
                  params: {
                    id: getUserId(links.packersUser),
                    tab: 'activity'
                  }
                }"
                target="_blank"
                class="flex items-center space-x-2 group p-2"
              >
                <div
                  class="rounded group-hover:bg-gray-200 p-2 w-full flex items-center overflow-hidden"
                >
                  <div class="flex-grow leading-none">
                    <div class="font-semibold text-lg">{{ user.name }}</div>
                    <div class="text-gray-600 text-sm -mt-2">
                      {{ user.email }}
                    </div>
                  </div>
                  <div
                    class="flex items-center space-x-2 default-transition relative transform translate-x-6 group-hover:-translate-x-1"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'id-badge']"
                      fixed-width
                      class="text-2xl default-transition text-gray-500 group-hover:text-gray-900"
                    />
                    <div
                      class="default-transition opacity-0 group-hover:opacity-100"
                    >
                      &rarr;
                    </div>
                  </div>
                </div>
              </router-link>
              <div class="divide-y">
                <div class="" v-if="packingItem.currentDetails.customOrder">
                  <div
                    class="bg-yellow-100 text-gray-900 border-t p-4 flex space-x-4 items-center"
                  >
                    <div class="text-lg">
                      <font-awesome-icon :icon="['fal', 'circle-info']" />
                    </div>
                    <div>
                      Manual order created by
                      {{ packingItem.currentDetails.packer.name }}
                    </div>
                  </div>
                </div>
                <div class="p-4 space-y-2" v-else>
                  <div
                    v-for="(cat, index) in activeCats"
                    :key="`cat-${index}`"
                    class="truncate flex items-center space-x-2"
                  >
                    <cat-image
                      :cat="cat"
                      class="border-2 border-white rounded-full overflow-hidden w-12 h-12 flex-none"
                    />
                    <div class="flex-grow flex-col truncate">
                      <div class="space-x-1 truncate">
                        <span class="truncate">{{ cat.name }}</span>
                        <span v-if="cat.isKitten">(Kitten)</span>
                      </div>
                      <div class="text-gray-400">
                        {{ cat.gender | startCase }} &middot; {{ cat.tribe }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-4 space-y-4">
                  <div>
                    <div class="flex items-center mb-2">
                      <div class="flex-grow font-semibold text-lg text-left">
                        Shipping Address
                      </div>
                      <packing-update-address
                        v-if="isAdmin"
                        :address="packingAddress"
                      />
                    </div>
                    <div class="flex flex-col py-1">
                      <div>
                        {{ packingAddress.address1 }}
                      </div>
                      <div>
                        {{ packingAddress.address2 }}
                      </div>
                      <div>
                        {{ packingAddress.address3 }}
                      </div>
                      <div>
                        {{ packingAddress.postcode }}
                      </div>
                      <div class="mt-4 space-y-4">
                        <div class="mt-3">{{ user.phone }}</div>
                      </div>
                    </div>

                    <div
                      class="mt-4 space-y-4"
                      :class="{
                        'opacity-50 pointer-events-none': packingItem.isOnHold
                      }"
                    >
                      <div
                        class="flex items-center"
                        v-if="!packingItem.isSample"
                      >
                        <div class="relative flex items-center w-full">
                          <!-- <radio-buttons
                            :options="parcelsNo"
                            v-model="noOfParcels"
                            is-full
                            :disabled="packingItem.hasLabel"
                          /> -->
                          <select-dropdown
                            :class="{
                              'pointer-events-none': packingItem.hasLabel
                            }"
                            class="w-full flex items-center relative"
                          >
                            <select
                              id="label-box"
                              v-model="noOfParcels"
                              :disabled="packingItem.hasLabel"
                            >
                              <option
                                :key="`${option.label}-option`"
                                v-for="option in parcelsNo"
                                :value="option.value"
                              >
                                <font-awesome-icon
                                  v-for="(box, index) in option.icon"
                                  :key="`box-${index}`"
                                  :icon="['fal', box]"
                                  fixed-width
                                />{{ option.label }}
                              </option>
                            </select>
                            <div
                              class="absolute flex items-center space-x-2 right-11 pointer-events-none space-x-1"
                            >
                              <div class="text-gray-500 text-xs font-mono">
                                {{ currentWeight }}kg
                              </div>
                              <font-awesome-icon
                                v-for="i in noOfParcels"
                                :key="i"
                                :icon="['fal', 'box-open']"
                                class="text-gray-600 text-xl"
                              />
                            </div>
                          </select-dropdown>
                        </div>
                      </div>

                      <print-shipping-btn
                        :link="packingLinks.packersLabel"
                        :remove="packingLinks.trashLabel"
                        :weight="currentWeight"
                        :parcels="noOfParcels"
                        :user="user"
                        :item="packingItem"
                        :delivery-override="
                          packingItem.currentDetails.deliveryTypeOverride
                        "
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="p-4"
                  :class="{
                    'opacity-50 pointer-events-none': packingItem.isOnHold
                  }"
                >
                  <packing-checklist
                    :links="packingLinks"
                    :is-packed="isPacked"
                    :is-shipped="isShipped"
                    :user="user"
                  />
                </div>
              </div>
            </div>
          </div>
        </loading>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';
import { split, last, isNull, filter, isEmpty } from 'lodash/fp';

import PackingList from '@/components/common/ui/packing/PackingList';
import PackingChecklist from '@/components/common/ui/packing/PackingChecklist';
import Loading from '@/components/common/ui/Loading';
import PrintShippingBtn from '@/components/common/printer/PrintShippingBtn';
import PackingActions from '@/components/common/ui/packing/PackingActions';
import PackingUpdateAddress from '@/components/common/ui/packing/PackingUpdateAddress';
import utils from '@/utils';
import { mapGetters } from 'vuex';
import PackingProgress from '@/components/common/ui/packing/PackingProgress.vue';
import StatusLabel from '@/components/common/ui/ColorLabel';
import SelectDropdown from '@/components/common/ui/SelectDropdown.vue';
import CatImage from '../../components/common/ui/CatImage.vue';

export default {
  metaInfo() {
    return {
      titleTemplate: `#${this.$route.params.id} - ${this.user.name} - CCC Admin`
    };
  },
  components: {
    PackingList,
    PackingChecklist,
    PackingActions,
    PrintShippingBtn,
    PackingUpdateAddress,
    Loading,
    PackingProgress,

    StatusLabel,
    SelectDropdown,
    CatImage
  },
  data() {
    return {
      packingLinks: undefined,
      user: '',
      links: undefined,
      isLoading: true,
      noOfParcels: null,
      parcelsNo: [
        {
          value: 1,
          label: '1 Box',
          icon: ['box-open']
        },
        {
          value: 2,
          label: '2 Boxes',
          icon: ['box-open', 'box-open']
        },
        {
          value: 3,
          label: '3 Boxes',
          icon: ['box-open', 'box-open', 'box-open']
        }
      ]
    };
  },
  computed: {
    currentRoute() {
      return this.$route.params.id;
    },
    bonusItems() {
      return this.packingItem?.bonus?.products;
    },
    packingName() {
      return `#${this.packingItem.invId} - ${this.user.name}`;
    },
    isMultiTaster() {
      return this.packingItem.cats.length > 1;
    },
    isPacked() {
      return !isNull(this.packingItem.packedAt);
    },
    isShipped() {
      return !isNull(this.packingItem.shippedAt);
    },
    currentWeight() {
      return this.calculateWeight(this.packingItem.cats, true);
    },
    activeFlyers() {
      // find the _FLYERS in packaging.products
      return this.packingItem?.packaging?.products.filter(product =>
        product.sku.includes('_FLYER_')
      );
    },
    activeCats() {
      const cats = this.packingItem.cats.filter(cat => cat.active);
      // for each of the active cats merge with the same cat from user.cats
      return cats.map(cat => {
        const userCat = this.user.cats.find(
          userCat => userCat.name === cat.name
        );
        return { ...cat, ...userCat };
      });
    },
    ...mapGetters({
      isAdmin: 'isAdmin',
      packingItem: 'currentPackingItemGetter',
      boxSize: 'currentPackingBoxGetter'
    }),
    packingAddress() {
      return {
        address1: this.packingItem.currentDetails.address1
          ? this.packingItem.currentDetails.address1
          : this.user.addr1,
        address2: this.packingItem.currentDetails.address2
          ? this.packingItem.currentDetails.address2
          : this.user.addr2,
        address3: this.packingItem.currentDetails.address3
          ? this.packingItem.currentDetails.address3
          : this.user.addr3,
        postcode: this.packingItem.currentDetails.postcode
          ? this.packingItem.currentDetails.postcode
          : this.user.postcode
      };
    }
  },
  watch: {
    currentRoute: {
      handler() {
        this.getPackingItem();
      }
    }
  },
  mounted() {
    this.getPackingItem();
  },
  methods: {
    hasBonus(pack) {
      return !isEmpty(pack.bonus.products);
    },
    getPackingItem() {
      this.$store
        .dispatch('getPackingItem', this.$route.params.id)
        .then(response => {
          this.$mixpanel.time_event('timeToPack');
          this.packingLinks = response.links;
          this.$store.dispatch('createPackingChecklist', this.packingItem);
          this.isLoading = false;
          this.noOfParcels = this.boxSize?.totalBoxes || 1;
          http
            .get(utils.sanitizeApiUrl(response.links.packersUser))
            .then(response => {
              this.user = response.data.content[0];
              if (utils.stringToBoolean(this.user.settings['isNi'])) {
                this.$store.commit('update_packing_checklist', {
                  type: 'I_PL',
                  value: utils.stringToBoolean(this.user.settings['isNi'])
                });
              }
              this.links = response.data.links;
            })
            .catch(() => {});
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getUserId(url) {
      return last(split(['/'], url));
    },
    onlyActive(cats) {
      return filter(['active', true], cats);
    },
    calculateWeight(cats, noFormat) {
      const needsTaster = this.packingItem.isSample;
      return utils.calculateWeight(cats, needsTaster, noFormat);
    }
  },
  destroyed() {
    this.$store.commit('reset_packing_checklist');
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  @apply w-full sm:w-96;
}
</style>
