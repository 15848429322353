<template>
  <div>
    <div>
      <div
        @click="toggleModal"
        class="hover:underline cursor-pointer text-sm text-center text-gray-500 pt-4"
      >
        Reset label
      </div>
    </div>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="reset-label-modal"
      size="xxs"
      :modal-title="`Reset label`"
    >
      <div class="flex flex-col space-y-6">
        <div class="flex-col flex space-y-4">
          <div>
            You are about to reset the label on this packing item. This cannot
            be undone.
          </div>
          <div>Are you sure?</div>
        </div>
        <div class="flex space-x-4">
          <form @submit.prevent="removeLabel">
            <loading-button label="Yes, reset the label" :is-loading="false" />
          </form>
          <form @submit.prevent="toggleModal">
            <loading-button label="Cancel" :is-loading="false" secondary />
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import LoadingButton from '../ui/LoadingButton.vue';
import Modal from '../ui/Modal.vue';
export default {
  name: 'ResetLabel',
  components: { LoadingButton, Modal },
  props: { link: String },
  data() {
    return {
      modalVisible: false
    };
  },

  methods: {
    ...mapMutations({
      updateItem: 'update_packing_item_key'
    }),
    removeLabel() {
      this.$store
        .dispatch('removeShippingLabel', {
          link: this.link
        })
        .then(() => {
          this.updateItem({
            key: 'hasLabel',
            payload: false
          });
        });
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    }
  }
};
</script>

<style></style>
