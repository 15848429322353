<template>
  <div class=" ">
    <div v-if="!isPacked && !isShipped">
      <div class="flex items-center justify-between mb-3">
        <div class="font-semibold text-lg">
          Checklist
        </div>
        <div class="text-gray-600">
          {{ completedCount }} / {{ filteredChecklist.length }}
        </div>
      </div>

      <div>
        <label
          class="flex items-center py-1"
          v-for="item in filteredChecklist"
          :key="item.key"
          v-show="item.isRequired"
          :class="{
            'line-through text-gray-600': item.isCompleted,
            'pointer-events-none': !item.userCan
          }"
        >
          <input
            type="checkbox"
            class="mr-2"
            v-model="item.isCompleted"
            :class="{
              'opacity-25': !item.userCan && !item.isCompleted
            }"
          />
          {{ item.label }}
        </label>
      </div>
    </div>
    <div class="mt-8" v-if="!isShipped">
      <transition
        enter-active-class="miniFadeInUp"
        leave-active-class="miniFadeOutDown"
        mode="out-in"
      >
        <div key="markPostedBtn" v-if="!hasPacked">
          <form @submit.prevent="markAsPacked">
            <loading-button
              is-full
              :is-loading="isLoading"
              :is-enabled="isValid"
              :label="!isPacked ? 'Mark as packed' : 'Mark as shipped'"
              large
            />
          </form>
        </div>
        <div
          v-else
          key="markPostedSuccess"
          class="text-center py-2 w-full rounded bg-green-500 text-white "
        >
          <font-awesome-icon
            :icon="['fal', 'check']"
            class="text-white mr-2 "
          />{{ !isPacked ? 'Marked as packed' : 'Marked as shipped' }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { isEmpty, filter } from 'lodash/fp';
import LoadingButton from '@/components/common/ui/LoadingButton';
import utils from '@/utils';
export default {
  components: {
    LoadingButton
  },
  props: {
    links: {
      type: Object,
      required: true
    },
    isPacked: {
      type: Boolean,
      default: false
    },
    isShipped: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      hasPacked: false,
      hasError: false
    };
  },
  computed: {
    ...mapGetters({
      packingChecklist: 'getPackingChecklist',
      packingItem: 'currentPackingItemGetter',
      packingProgress: 'packingProgressGetter'
    }),
    isValid() {
      return (
        isEmpty(filter(['isCompleted', false], this.filteredChecklist)) ||
        this.isPacked
      );
    },
    completedCount() {
      return filter(['isCompleted', true], this.filteredChecklist).length;
    },
    filteredChecklist() {
      return filter(['isRequired', true], this.packingChecklist);
    },
    itemType() {
      return this.$route.query.type;
    },
    currentIndex() {
      const current = this.packingProgress.items[this.itemType].findIndex(
        i => i.dispatchid === this.$route.params.id.toString()
      );

      return current;
    },
    isLast() {
      return (
        this.currentIndex + 1 ===
        this.packingProgress.items[this.itemType].length
      );
    },
    nextPackingItem() {
      if (!this.isLast) {
        const next = this.packingProgress.items[this.itemType][
          this.currentIndex + 1
        ].dispatchid;
        this.setNextItem(next);
        return next;
      }

      this.setNextItem(null);
      return null;
    }
  },
  methods: {
    ...mapMutations({
      setNextItem: 'set_next_item'
    }),
    markAsPacked() {
      this.isLoading = true;
      this.$store
        .dispatch(
          !this.isPacked ? 'markAsPacked' : 'markAsShipped',
          !this.isPacked ? this.links.packersPack : this.links.packersShip
        )
        .then(() => {
          this.hasPacked = true;
          this.$store.dispatch('getShippingList');
          this.$mixpanel.track('timeToPack', {
            id: this.packingItem.invId,
            type: this.packingItem.isSample ? 'Taster Box' : 'Subscription',
            amounts: utils.getSubscriptionAmounts(this.packingItem.cats)
          });
          this.isLoading = false;
          console.log('GO NEXT');
          if (this.isLast) {
            console.log('is last go dashboard');
            this.$router.push({ name: 'dashboard' });
          } else {
            console.log('go next item', this.nextPackingItem, this.itemType);
            this.$router.push({
              name: 'packingProfile',
              params: { id: this.nextPackingItem },
              query: { type: this.itemType }
            });
          }
        })
        .catch(() => {
          this.hasError = true;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="css" scoped></style>
