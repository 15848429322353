<template>
  <div class="flex flex-col" v-if="newList">
    <div
      class="sticky top-0 bg-white z-20  px-8 default-transition"
      v-scroll-shadow
    >
      <div class="flex items-center justify-between">
        <div class="text-xl flex items-center font-semibold py-5 space-x-2">
          <div>Shipping List</div>
          <span class="text-gray-400 font-normal ">({{ newList.length }})</span>
        </div>
        <div class="flex items-center space-x-4">
          <div v-if="selectedList.length > 0" class="text-gray-500">
            {{ selectedList.length }}
            {{ selectedList.length > 1 ? 'items' : 'item' }} selected
          </div>
          <form @submit.prevent="toggleModal">
            <loading-button
              :is-loading="false"
              :is-enabled="selectedList.length > 0"
              v-if="shippingList && shippingList.length > 0"
            >
              Mark as shipped
            </loading-button>
          </form>
        </div>
      </div>
      <div
        class="mb-4 flex space-x-4 overflow-x-auto  items-center dixide-x"
        v-if="shippingList && shippingList.length > 0"
      >
        <div>
          <radio-buttons
            :options="['all', 'subscriptions', 'taster box']"
            v-model="filters.activeType"
          />
        </div>
        <div
          v-if="isAdmin"
          class="flex items-center space-x-4 flex-none border-l pl-4"
        >
          <dropdown-button :is-active="filters.activeAssignee">
            <template v-slot:button>
              <div
                class="flex items-center space-x-2"
                :class="filters.activeAssignee ? 'text-white' : 'text-gray-400'"
              >
                <div class="text-gray-500">
                  <div v-if="filters.activeAssignee">
                    <avatar
                      class="flex-none "
                      size="xs"
                      :email="filters.activeAssignee.packername"
                      v-if="filters.activeAssignee.packerid !== 12"
                    />
                    <div v-else class="text-gray-400">
                      <font-awesome-icon :icon="['fal', 'user-slash']" />
                    </div>
                  </div>
                  <font-awesome-icon v-else :icon="['fal', 'users']" />
                </div>

                <div>
                  {{
                    filters.activeAssignee
                      ? filters.activeAssignee.packername
                      : 'All Packers'
                  }}
                </div>
              </div>
            </template>
            <div class=" py-2">
              <div class="border-b pb-2 mb-2">
                <div
                  class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                  @click="setActivePacker(null)"
                >
                  <div class="text-gray-500">
                    <font-awesome-icon :icon="['fal', 'users']" />
                  </div>
                  <div class="flex-grow truncate">All Packers</div>
                  <div v-if="!filters.activeAssignee">
                    <font-awesome-icon :icon="['fal', 'check']" />
                  </div>
                </div>
              </div>
              <div
                v-for="packer in availablePackers"
                :key="`packer-${packer.packerid}`"
                class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                @click="setActivePacker(packer)"
              >
                <avatar
                  class=" flex-none "
                  size="sm"
                  :email="packer.packername"
                  v-if="packer.packerid !== 12"
                />
                <div v-else class="text-gray-400">
                  <font-awesome-icon :icon="['fal', 'user-slash']" />
                </div>

                <div
                  class="flex-grow truncate"
                  :class="{ 'text-gray-400': packer.packerid === 12 }"
                >
                  {{ packer.packername }}
                </div>
                <div v-if="filters.activeAssignee">
                  <font-awesome-icon
                    v-if="filters.activeAssignee.packerid == packer.packerid"
                    :icon="['fal', 'check']"
                  />
                </div>
              </div>
            </div>
          </dropdown-button>
          <div class="border-l pl-4 h-full">
            <dropdown-button :is-active="filters.activeShipping">
              <template v-slot:button>
                <div
                  class="flex items-center space-x-2"
                  :class="
                    filters.activeShipping ? 'text-white' : 'text-gray-400'
                  "
                >
                  <div class="text-gray-500">
                    <div v-if="filters.activeShipping">
                      <div class="w-8">
                        <shipped-with
                          override-size
                          is-icon
                          :service="filters.activeShipping"
                        />
                      </div>
                    </div>
                    <font-awesome-icon
                      v-else
                      :icon="['fal', 'truck-ramp-box']"
                    />
                  </div>

                  <div>
                    {{
                      filters.activeShipping
                        ? filters.activeShipping.courier
                        : 'All Couriers'
                    }}
                  </div>
                </div>
              </template>
              <div class=" py-2">
                <div class="border-b pb-2 mb-2">
                  <div
                    class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                    @click="setActiveShipping(null)"
                  >
                    <div class="text-gray-500">
                      <font-awesome-icon :icon="['fal', 'truck-ramp-box']" />
                    </div>
                    <div class="flex-grow truncate">All Couriers</div>
                    <div v-if="!filters.activeShipping">
                      <font-awesome-icon :icon="['fal', 'check']" />
                    </div>
                  </div>
                </div>

                <div
                  v-for="shipping in availableShipping"
                  :key="`shipping-${shipping.type}`"
                  class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                  @click="setActiveShipping(shipping)"
                >
                  <div class="w-8">
                    <shipped-with override-size is-icon :service="shipping" />
                  </div>
                  <div class="flex-grow truncate">{{ shipping.courier }}</div>
                  <div v-if="filters.activeShipping">
                    <font-awesome-icon
                      v-if="filters.activeShipping.type == shipping.type"
                      :icon="['fal', 'check']"
                    />
                  </div>
                </div>
              </div>
            </dropdown-button>
          </div>
        </div>
      </div>
    </div>
    <div class="relative  flex flex-col flex-grow px-6 ">
      <loading :is-loading="isLoading" is-full class="flex-grow flex flex-col">
        <div class="flex-grow w-full overflow-x-auto">
          <div v-if="shippingList && shippingList.length > 0">
            <div
              class="grid  auto-cols-min whitespace-nowrap   "
              :class="isAdmin ? 'grid-cols-6' : 'grid-cols-5'"
            >
              <div class=" table-head contents">
                <div class="flex justify-center w-10">
                  <input type="checkbox" v-model="selectAll" />
                </div>
                <div></div>
                <div>Type</div>
                <div v-if="isAdmin">Packed by</div>
                <div>Packed at</div>
                <div>Dispatch</div>
                <div></div>
              </div>
              <div
                v-for="(item, index) in newList"
                :key="`item-${index}`"
                class="group flex items-center py-2 hover:bg-gray-50 contents table-item "
                :class="{ 'is-selected': item.isSelected }"
              >
                <div class="flex justify-center">
                  <input type="checkbox" v-model="item.isSelected" />
                </div>

                <router-link
                  :to="{
                    name: 'shippingProfile',
                    params: { id: item.dispatchid }
                  }"
                  tag="div"
                  class="flex group cursor-pointer "
                >
                  <div class="flex space-x-2 flex-grow pr-4 ">
                    <div
                      class="  w-32 text-center rounded mr-2"
                      :class="statusClass(item.dispatchAt)"
                    >
                      #{{ item.invId }}
                    </div>
                    <div class="flex-grow truncate ">
                      {{ item.name }}
                    </div>
                    <div
                      v-if="item.notes || item.hasFutureNote"
                      class="flex items-center relative"
                    >
                      <div
                        class="w-5 h-4 rounded bg-yellow-200 absolute top-0"
                      ></div>
                      <font-awesome-icon
                        :icon="['fal', 'message-lines']"
                        fixed-width
                        class=" transform rotate-12 text-lg text-gray-700 "
                      />
                    </div>
                  </div>
                </router-link>

                <div class="flex-grow flex items-center space-x-1">
                  <div>
                    {{ item.isSubscription ? 'Subscription' : 'Taster Box' }}
                  </div>
                  <div v-if="item.cats > 1 && !item.isSubscription">
                    ({{ item.cats }})
                  </div>
                </div>
                <div class="truncate py-0 " v-if="isAdmin">
                  <div class="w-full flex items-center">
                    <div class="flex">
                      <avatar
                        class="mr-2 flex-none "
                        size="sm"
                        :email="item.assignedTo.packername"
                      />
                      {{ item.assignedTo.packername }}
                    </div>
                  </div>
                </div>
                <div>
                  {{ item.packedAt | formatDate('D MMM, YYYY') }}
                  <span class="text-gray-500 ml-1">{{
                    item.packedAt | formatDate('h:mm a')
                  }}</span>
                </div>
                <div :class="{ 'text-red-500': dateBefore(item.dispatchAt) }">
                  {{ item.dispatchAt | formatDate('D MMM, YYYY') }}
                </div>
                <div class="flex justify-center">
                  <shipped-with is-icon :service="item.shippingType" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="flex-grow h-full flex items-center justify-center text-gray-400"
          >
            <div>
              <div class="mb-3 mx-auto w-24 h-24 text-4xl text-center">
                <img src="images/success.gif" />
              </div>
              Whooo... all done, nothing left to ship!
            </div>
          </div>
        </div>
        <modal
          :open="modalVisible"
          :on-close="toggleModal"
          id="mark-as-shipped"
          :modal-title="
            `Mark ${selectedList.length}
      ${selectedList.length > 1 ? 'items' : 'item'} as shipped`
          "
        >
          <p>
            You are about to mark {{ selectedList.length }}
            {{ selectedList.length > 1 ? 'items' : 'item' }} as shipped. Please
            confirm this is what you want to do
          </p>
          <div class="flex  space-x-4 mt-8">
            <form @submit.prevent="markAsShipped">
              <loading-button
                :is-loading="isLoading"
                :label="
                  `Yes, mark ${selectedList.length}
      ${selectedList.length > 1 ? 'items' : 'item'} as shipped`
                "
              />
            </form>
            <form @submit.prevent="toggleModal">
              <loading-button label="Cancel" secondary />
            </form>
          </div>
        </modal>
      </loading>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/common/ui/Avatar';
import LoadingButton from '@/components/common/ui/LoadingButton';
import DropdownButton from '@/components/common/ui/DropdownButton';
import Loading from '@/components/common/ui/Loading';
import Modal from '@/components/common/ui/Modal';
import http from '@/http';
import { mapGetters } from 'vuex';
import utils from '@/utils';
import { filter, orderBy, uniqBy } from 'lodash/fp';
import RadioButtons from '@/components/common/ui/RadioButtons';
import ShippedWith from '@/components/common/ui/ShippedWith.vue';
export default {
  metaInfo() {
    return {
      titleTemplate: `Shipping (${this.shippingCount}) - CCC Admin`
    };
  },
  components: {
    Avatar,
    LoadingButton,
    Modal,
    Loading,
    RadioButtons,
    DropdownButton,
    ShippedWith
  },
  data() {
    return {
      selectAll: false,
      isLoading: true,
      filters: utils.getStorage('shippingListFilters', 'session') || {
        activeType: 'all',
        activeAssignee: null,
        activeShipping: null
      },
      newList: null
    };
  },
  computed: {
    selectedList() {
      if (this.newList) {
        return this.newList.filter(l => l.isSelected);
      }
      return [];
    },

    ...mapGetters({
      shippingList: 'getShippingList',
      shippingCount: 'getShippingCount',
      modalVisible: 'getModalVisible',
      isAdmin: 'isAdmin'
    }),

    availablePackers() {
      const packers = [];
      this.shippingList.forEach(p => {
        packers.push(p.assignedTo);
      });

      return orderBy(['packername'], ['asc'], uniqBy('packerid', packers));
    },
    availableShipping() {
      const shipping = [];
      this.shippingList.forEach(p => {
        shipping.push(p.shippingType);
      });

      return orderBy(['courier'], ['asc'], uniqBy('type', shipping));
    }
  },
  watch: {
    selectAll(newValue) {
      this.shippingList.forEach(i => {
        this.$set(i, 'isSelected', newValue);
      });
    },
    filters: {
      deep: true,
      immediate: false,
      handler(newValue) {
        utils.setStorage('shippingListFilters', newValue, 'session');
        this.filterTheList();
      }
    }
  },
  methods: {
    getShippingList() {
      this.$store.dispatch('getShippingList').then(() => {
        this.isLoading = false;
        if (this.shippingList.length > 0) {
          this.shippingList.map(ship => {
            ship.fl_courier = ship.shippingType?.type;
            ship.fl_type = ship.isSubscription ? 'subscriptions' : 'taster box';
            ship.fl_assigned = ship.assignedTo?.packerid;
          });
        }
        this.filterTheList();
      });
    },
    dateBefore(date) {
      return utils.dateBefore(date);
    },
    getCount(list, type) {
      return filter(function(o) {
        return o[type];
      }, list).length;
    },
    setActivePacker(packer) {
      this.filters.activeAssignee = packer;
    },
    setActiveShipping(shipping) {
      this.filters.activeShipping = shipping;
    },

    statusClass(date) {
      if (this.dateBefore(date)) {
        return 'text-red-500 bg-red-50 ';
      }
      return 'text-gray-400';
    },
    toggleModal() {
      utils.toggleModal();
    },
    markAsShipped() {
      this.isLoading = true;
      const promises = [];

      this.selectedList.forEach(i => {
        promises.push(http.post(utils.sanitizeApiUrl(i.links.packersShip)));
      });
      Promise.allSettled(promises)
        .then(() => {
          this.isLoading = false;
          if (this.modalVisible) {
            utils.toggleModal();
          }
          this.getShippingList();
        })
        .catch(error => {
          console.log(`Error in executing ${error}`);
        });
    },
    filterTheList() {
      if (this.shippingList.length > 0) {
        var filter = {
          fl_courier: this.filters.activeShipping
            ? this.filters.activeShipping?.type
            : this.availableShipping.map(s => s.type),
          fl_assigned: this.filters.activeAssignee
            ? this.filters.activeAssignee?.packerid
            : this.availablePackers.map(p => p.packerid),
          fl_type:
            this.filters.activeType === 'all'
              ? ['taster box', 'subscriptions']
              : this.filters.activeType === 'subscriptions'
              ? 'subscriptions'
              : 'taster box'
        };

        let sort = ['packedAt'];
        let order = ['asc'];
        this.newList = this.shippingList.filter(item => {
          return Object.keys(filter).every(key => {
            if (filter[key] instanceof Array) {
              return filter[key].includes(item[key]);
            } else {
              return filter[key] === item[key];
            }
          });
        });

        this.newList = orderBy(sort, order, this.newList);
      } else {
        this.newList = [];
      }
    }
  },
  mounted() {
    this.getShippingList();
  }
};
</script>

<style lang="scss" scoped>
.grid {
  &.grid-cols-6 {
    grid-template-columns:
      max-content
      auto minmax(min(200px, 200px), max-content)
      minmax(min(200px, 200px), max-content) minmax(
        min(220px, 220px),
        max-content
      )
      minmax(min(120px, 120px), max-content) minmax(min(80px, 80px), max-content);
  }
  &.grid-cols-5 {
    grid-template-columns:
      max-content
      auto minmax(min(200px, 200px), max-content)
      minmax(min(220px, 220px), max-content)
      minmax(min(120px, 120px), max-content) minmax(min(80px, 80px), max-content);
  }
  .table-item > * {
    @apply whitespace-nowrap min-w-min px-4 sm:px-0;
  }
  // grid-template-columns: 4fr 2fr 2fr 2fr 2fr;
}
</style>
