<template>
  <div
    @click.prevent="toggleComplete"
    class="border rounded-lg cursor-pointer relative hover:bg-gray-50 w-96 default-transition"
    :class="{ 'is-completed': item.isCompleted }"
    v-if="this.product"
  >
    <cat-icon
      v-if="product.foodcategory === 'kitten' || item.sku === 'T_TST_KIT'"
      class="absolute origin-center -top-4 -left-4 w-10 h-10 p-2 border-4 border-white transform-gpu rounded-full flex items-center justify-center bg-gray-400 text-white z-40 default-transition"
      :class="{
        'bg-green-500 scale-75 -rotate-12 p-1 text-green-200 ': item.isCompleted
      }"
    />
    <div class="flex flex-row p-2 items-center relative h-24">
      <div
        class="w-20 h-20 rounded overflow-hidden bg-gray-200 bg-contain relative justify-between flex items-center flex-none bg-no-repeat bg-center"
        :class="{ 'bg-green-400 bg-blend opacity-50': item.isCompleted }"
        :style="imageStyle"
      >
        <div class="w-full text-center" v-if="!showImage(product.sku)">
          <font-awesome-icon
            :icon="['fal', 'image-polaroid']"
            class="text-4xl text-gray-300"
            :class="{ 'text-green-300': item.isCompleted }"
          />
        </div>
      </div>
      <div class="flex items-center justify-between flex-grow">
        <div class="pl-3">
          <div class="fomt-semibold text-xl">
            {{ getName(product) }}
            <span v-if="product.foodcategory === 'kitten'">(KITTEN)</span>
          </div>
          <div
            class="font-normal text-xs px-1 font-mono"
            :class="{
              'text-gray-500': !item.isCompleted,
              'text-green-100': item.isCompleted
            }"
          >
            {{ product.sku }}
            <span v-if="item.isCompleted"
              >({{
                this.productType === 'BAG'
                  ? item.total / item.increments
                  : item.total
              }})</span
            >
          </div>
        </div>
      </div>

      <transition
        enter-active-class="miniFadeInRight"
        leave-active-class="miniFadeOutRight"
        mode="out-in"
      >
        <div
          key="uncompleted-item"
          class="flex flex-row h-full default-transition"
          v-if="!item.isCompleted"
        >
          <div
            class="text-5xl flex items-center leading-none"
            v-html="convertAmount()"
          ></div>
          <div
            class="flex items-center justify-center text-xs w-10 h-full bg-gray-100 rounded ml-4"
          >
            <div
              class="transform -rotate-90 py-1 text-xs font-normal font-mono text-gray-600 text-center tracking-widest rounded"
            >
              {{ productType }}
            </div>
          </div>
        </div>
        <div key="completed-item" class="p-6" v-else>
          <font-awesome-icon :icon="['fad', 'box-check']" class="text-4xl" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { upperCase } from 'lodash/fp';
import utils from '@/utils';
import CatIcon from '@/components/common/ui/CatIcon';
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    CatIcon
  },

  computed: {
    productType() {
      if (this.product.foodtype === 'wet_pouch') {
        return upperCase('pouches');
      }
      if (this.product.foodtype === 'wet_tasty') {
        return upperCase('tasty slices');
      }
      if (this.product.foodtype === 'wet_chunk') {
        return upperCase('tasty chunks');
      }
      if (this.product.foodtype === 'wet_tray') {
        return upperCase('trays');
      }
      if (this.product.foodtype === 'wet_can') {
        return upperCase('cans');
      }
      if (this.product.foodtype === 'wet_smooth') {
        return upperCase('smooth');
      }
      if (this.product.foodtype === 'dry') {
        return upperCase('bag');
      }
      if (this.product.foodtype === 'box_insert') {
        return upperCase('flyer');
      }

      return upperCase('taster');
    },
    imageStyle() {
      if (this.showImage(this.product.sku)) {
        return {
          'background-image': `url(/images/products/${utils.getImage(
            this.product.sku
          )}.webp)`
        };
      }
      return '';
    }
  },
  data() {
    return {
      product: undefined,
      utils
    };
  },
  methods: {
    toggleComplete() {
      this.item.isCompleted = !this.item.isCompleted;
    },
    convertAmount() {
      if (this.product.foodtype === 'dry') {
        // return this.item.total < 1000
        //   ? this.item.total +
        //       "<span class='text-gray-500 font-normal text-lg  ml-2'>g</span>"
        //   : this.item.total / 1000 +
        //       "<span class='text-gray-500 text-lg font-normal ml-2'>kg</span>";
        return this.item.total / this.item.increments;
      } else {
        return this.item.total;
      }
    },

    showImage() {
      // if (includes("T_TST", sku)) {
      //   return false;
      // }
      return true;
    },
    getName() {
      if (this.product.sku === 'T_TST_KIT') {
        return 'Taster Box (Kitten)';
      }

      if (this.product.foodtype === 'taster') {
        return this.product.description;
      }

      return this.product?.shortname || this.product.name;
    }
  },
  mounted() {
    this.$store
      .dispatch('getProduct', { sku: this.item.sku })
      .then(response => {
        this.product = response;
      });
  }
};
</script>

<style lang="scss" scoped>
.is-completed {
  @apply bg-green-400 text-white border-green-500;
  &:hover {
    @apply bg-green-500;
  }
}

.bg-blend {
  background-blend-mode: multiply, saturation;
}
</style>
