<template>
  <div v-if="clonedAddress">
    <div
      class="hover:underline cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>Update</div>
      <div>&rarr;</div>
    </div>

    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      id="update-packing-address"
      modal-title="Update Address"
    >
      <form @submit.prevent="updateAddress" class="space-y-4">
        <div class="flex space-x-4 items-center">
          <div class="w-48">
            Address line 1
          </div>
          <div class="flex-grow">
            <input
              type="text"
              ref="addressLine1"
              v-model="clonedAddress.address1"
            />
          </div>
        </div>
        <div class="flex space-x-4 items-center">
          <div class="w-48">
            Address line 2
          </div>
          <div class="flex-grow">
            <input
              type="text"
              ref="addressLine2"
              v-model="clonedAddress.address2"
            />
          </div>
        </div>
        <div class="flex space-x-4 items-center">
          <div class="w-48">
            Town / City
          </div>
          <div class="flex-grow">
            <input
              type="text"
              ref="addressLine3"
              v-model="clonedAddress.address3"
            />
          </div>
        </div>
        <div class="flex space-x-4 items-center">
          <div class="w-48">
            Postcode
          </div>
          <div class="w-32">
            <input
              type="text"
              ref="postcode"
              class="uppercase"
              v-model="clonedAddress.postcode"
            />
          </div>
        </div>

        <div
          v-if="hasError"
          class=" bg-red-100 p-4 text-red-400 rounded mb-4 error-msg"
        >
          {{
            errorMessage
              ? errorMessage
              : 'There was an error updating this user address'
          }}. Try again
        </div>
        <loading-button :is-loading="isLoading" :is-enabled="true">
          Update</loading-button
        >
      </form>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { clone } from 'lodash/fp';

export default {
  name: 'PackingUpdateAddress',
  components: {
    Modal,
    LoadingButton
  },
  props: {
    address: {
      type: Object
    }
  },
  data() {
    return {
      clonedAddress: clone(this.address),
      modalVisible: false,
      hasError: false,
      isLoading: false
    };
  },
  watch: {
    address(newValue) {
      console.log('has address');
      if (newValue) {
        console.log('is new');
        this.clonedAddress = newValue;
        console.log('hasCloned', this.clonedAddress);
      }
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'getCurrentUserProfile'
    })
  },
  methods: {
    ...mapActions({
      getUser: 'getUser',
      getPackingItem: 'getPackingItem'
    }),
    ...mapMutations({
      updateItem: 'update_packing_item_key'
    }),
    reset() {},
    toggleModal() {
      this.modalVisible = !this.modalVisible;
      if (!this.modalVisible) {
        this.reset();
      }
    },
    updateAddress() {
      this.isLoading = true;
      this.hasError = false;
      this.$store
        .dispatch('updatePackingAddress', {
          uid: this.$route.params.id,
          data: this.clonedAddress
        })
        .then(() => {
          this.updateItem({
            key: 'currentDetails',
            payload: this.clonedAddress
          });
          this.isLoading = false;
          this.toggleModal();
        })
        .catch(err => {
          console.log(err);
          this.isLoading = false;
          this.errorMessage = err;
          this.hasError = true;
        });
    }
  },
  mounted() {
    this.reset();
  }
};
</script>

<style></style>
